<template>
  <div
    class="redeem-btn d-flex justify-center align-center white--text rem-0 rem-md-2 can-click"
    :class="{'rounded': rounded}"
    :style="`background: ${color}`"
    @click="$emit('clickBtn')"
  >
    {{ $t(btnText) }}
  </div>
</template>

<script>
export default {
  props: {
    btnText: {
      type: String,
      default: 'redeemImmediately'
    },
    color: {
      type: String,
      default: '#3DA353'
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.redeem-btn {
  width: 77px;
  height: 28px;
  @include dai_vuetify_md {
    width: 55px;
    height: 20px;
    font-size: 10px;
  }
}
</style>