<template>
  <v-container class="order-claim-page">
    <div class="d-flex justify-space-between align-center rem-md-24 rem-6 primary--text mb-mb-14 mb-10">
      <img class="can-click" src="@/assets/img/icon-arrow-left.png" alt="arrow-left" @click="$router.go(-1)">
      {{ $t('redeemTitle') }}
      <div></div>
    </div>

    <div class="d-flex justify-center mb-10">
      <div class="product-wrap d-flex flex-md-row flex-column align-stright w-100">
        <div class="product-img-wrap d-flex justify-center align-center mr-14 mb-5 mb-md-0">
          <img :src="info.image" :alt="info.name">
        </div>

        <div class="product-info flex-column justify-space-between">
          <div class="rem-md-23 rem-8 mb-6">{{ info.product_name }}</div>
          <div class="rem-md-26 rem-10 font-weight-black">剩餘 {{ info.amount }} 支</div>

          <div class="redeem-btns d-flex">
            <redeemBtn class="mr-2" btnText="宅配領取" @clickBtn="redeemShow = true; redeemType = 1"></redeemBtn>
            <redeemBtn btnText="現場領取" @clickBtn="redeemShow = true; redeemType = 2"></redeemBtn>
          </div>
        </div>
      </div>
    </div>

    <hr class="line mb-10" />

    <section class="claim-history">
      <div class="rem-md-16 rem-4 claim-history-item mb-md-3 mb-2 py-md-3 px-md-10 py-2 px-3" v-for="(item, i) in info.pack_history" :key="i">
        <div class="d-flex justify-space-between mb-md-4 mb-2">
          {{ timestampToDate(item.order_time * 1000) }}
          <div>已領取 {{ item.amount }} 支</div>
        </div>
        <div>{{ item.ship_type }}：{{ item.ship_address }}</div>
      </div>
    </section>
    
    <!-- claim dialog -->
    <v-dialog
      class="redeem-dialog"
      v-model="redeemShow"
      width="500"
    >
      <v-card
        class="redeem-dialog-content d-flex flex-column align-center"
        rounded="xl"
        color="#EFEBEB"
      >
        <div class="rem-md-22 rem-13 mb-7 darkGrey--text mb-6 mt-7">立即領取</div>

        <v-form class="w-100 px-7" ref="form" lazy-validation>
          <v-text-field
            class="redeem-input mb-md-15 mb-3"
            color="darkGrey"
            :height="$store.state.nowWidth <= 960 ? 42 : 65"
            full-width
            outlined
            hide-details="auto"
            placeholder="請輸入欲領取的商品數量"
            :rules="[...AmountRules]"
            type="number"
            v-model="amount"
          ></v-text-field>

          <v-radio-group class="redeem-types d-block d-md-none" v-model="redeemType" row>
            <v-radio
              color="black"
              class="rem-6"
              v-for="item in redeemTypes"
              :key="item.text"
              :label="item.text"
              :value="item.value"
            ></v-radio>
          </v-radio-group>

          <v-text-field
            v-if="redeemType === 1"
            class="redeem-input mb-md-8 mb-5"
            color="darkGrey"
            :height="$store.state.nowWidth <= 960 ? 42 : 65"
            full-width
            outlined
            placeholder="請輸入宅配地址"
            hide-details="auto"
            :rules="[required]"
            v-model="address"
          ></v-text-field>

          <v-text-field
            v-else
            class="redeem-input mb-md-8 mb-5"
            color="darkGrey"
            :height="$store.state.nowWidth <= 960 ? 42 : 65"
            full-width
            outlined
            placeholder="請輸入服務人員名稱"
            hide-details="auto"
            :rules="[required]"
            v-model="address"
          ></v-text-field>

          <v-checkbox
            class="primary--text mx-auto"
            style="width: fit-content;"
            v-model="is18"
            :label="redeemType === 1 ? '我確認我已滿１８歲' : '我確認有服務人員協助我領取商品'"
          ></v-checkbox>
        </v-form>

        <div class="w-100">
          <div class="dialog-btn py-7 text-center can-click rem-md-15 rem-6" @click="send()">{{ $t('confirm') }}</div>
          <div class="dialog-btn py-7 text-center can-click rem-md-15 rem-6" @click="redeemShow=false">{{ $t('cancel') }}</div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import base from '@/mixin/base'
import redeemBtn from '@/components/redeemBtn'
export default {
  name: "Order-Id",
  mixins: [base],
  data() {
    return {
      info: {},
      redeemTypes: [
        { text: '宅配', value: 1 },
        { text: '線下領取', value: 2 },
      ],
      redeemType: 1,
      redeemShow: false,
      amount: '',
      address: '',
      is18: false,
    }
  },
  watch: {
    redeemShow(newVal){
      if (newVal){
        let redeemType = this.redeemType
        this.$refs.form.reset()
        this.redeemType = redeemType
      }
    },
    amount(newVal){
      if (parseInt(newVal) > this.info.amount){
        this.$nextTick(() => {
          this.amount = this.info.amount
        })
      }
    }
  },
  components: {
    redeemBtn
  },
  methods: {
    async send(){
      if (this.$refs.form.validate()){
        if (this.is18){
          let result = await this.$store.dispatch('claimProducts', {
            order_id: this.$route.params.id,
            amount: parseInt(this.amount),
            ship_type: this.redeemTypes[this.redeemType-1].text,
            ship_address: this.address
          })
          console.log('result', result)
          if (result.status === 200){
            this.$toasted.show('領取成功')
            await this.getOrderDetail()
            this.$refs.form.reset()
            this.redeemShow = false
          }else{
            this.$toasted.show('領取失敗')
          }
        }else{
          this.$toasted.error('請勾選確認')
        }
      }
    },
    async getOrderDetail(){
      try {
        let result = await this.$store.dispatch('getRedeemOrderDetail', {
          order_id: this.$route.params.id
        })
        if (result.status === 200 && result.data.order_id){
          this.info = result.data
        }else{
          this.$router.push({name: 'Order', params: {lang: this.$store.state.locale}})
        }
      }catch (error){
        console.log('error', error)
        this.$router.push({name: 'Order', params: {lang: this.$store.state.locale}})
      }
    }
  },
  async mounted() {
    await this.getOrderDetail()
  }
}
</script>

<style lang="scss">
.order-claim-page {
  margin-bottom: 100px;
  .product-wrap {
    .product-img-wrap {
      height: 377px;
      background: #e8e8e8;
      @include dai_vuetify_md {
        width: 100%;
        aspect-ratio: 1;
      }
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
      }
      .product-img {
        width: 100%;
        padding-bottom: 100%;
        background-size: cover;
      }
    }
    .product-info {
      position: relative;
      width: calc(100%);
      .redeem-btns {
        position: absolute;
        right: 0;
        bottom: 0;
        .redeem-btn{
          width: 161px;
          height: 58px;
          font-size: 29px;
          @include dai_vuetify_md {
            width: 95px;
            height: 34px;
            font-size: 17px;
          }
        }
      }
    }
  }
  .claim-history-item{
    background: rgba(217, 217, 217, 0.33);
    border-radius: 8px;
  }
}

.v-dialog {
  border-radius: 24px !important;
  &::-webkit-scrollbar { display: none !important; }
}
.redeem-input {
  border-radius: 0 !important;
  .v-input__slot {
    padding: 0 24px !important;
  }
  input {
    font-size: 27px;
    @include dai_vuetify_md {
      font-size: 18px;
    }
  }
  .v-text-field__slot:before{
    content: '';
    width: 1.5px;
    height: 50%;
    margin: 0 9px 0 -9px;
    background: black;
  }
}
.v-input--checkbox {
  label {
    color: var(--v-primary-base) !important;
  }
}
.dialog-btn{
  width: 50%;
  display: inline-block;
  border-top: 1px solid #ABABAB;
  letter-spacing: .5em;
}
.dialog-btn:first-child{
  border-right: 1px solid #ABABAB;
}
.redeem-types {
  font-size: 18px;
  color: black;
}
.v-label {
  font-size: 21px !important;
}
</style>